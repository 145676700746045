<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.payments_expense") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'payments_expenses.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.type_expense.show">
                {{ columns.type_expense.title }}
              </th>
              <th v-if="columns.money_amount.show">
                {{ columns.money_amount.title }}
              </th>
              <th v-if="columns.branch.show">
                {{ columns.branch.title }}
              </th>
              <th v-if="columns.datas.show">
                {{ columns.datas.title }}
              </th>
              <th v-if="columns.account.show">
                {{ columns.account.title }}
              </th>
              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.type_expense.show">
                <select-type-expense
                  :placeholder="columns.type_expense.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.type_expense_id"
                ></select-type-expense>
                <!-- <crm-input
                                    :placeholder="columns.type_expense.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.type_expense_id"
                                ></crm-input> -->
              </th>
              <th v-if="columns.money_amount.show">
                <crm-input
                  :placeholder="columns.money_amount.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.money_amount"
                ></crm-input>
              </th>
              <th v-if="columns.branch.show">
                <select-branch
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.branch_id"
                ></select-branch>
                <!-- <crm-input
                                  :placeholder="columns.comment.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.comment"
                              ></crm-input> -->
              </th>

              <th v-if="columns.datas.show">
                <crm-date-picker
                  :placeholder="columns.datas.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.date"
                ></crm-date-picker>
              </th>
              <th v-if="columns.account.show">
                <select-account
                  :placeholder="columns.account.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.account_id"
                ></select-account>
              </th>
              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>
              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>
              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>
          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="payment_type in list"
              :key="payment_type.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ payment_type.id }}</td>

              <td v-if="columns.type_expense.show">
                {{ payment_type.type_expense.name }}
              </td>
              <td v-if="columns.money_amount.show">
                {{ payment_type.money_amount }}
                {{ payment_type.currency.symbol }}
              </td>
              <td v-if="columns.branch.show">
                {{ payment_type.branch ? payment_type.branch.name : "" }}
              </td>
              <td v-if="columns.datas.show">
                {{ payment_type.date }}
              </td>

              <td v-if="columns.account.show">
                {{ payment_type.account ? payment_type.account.name : "" }}
              </td>
              <td v-if="columns.comment.show">
                {{ payment_type.comment }}
              </td>
              <td v-if="columns.created_at.show">
                {{ payment_type.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ payment_type.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="payment_type"
                  :permissionShow="'payments_expenses.update'"
                  :permissionDestroy="'payments_expenses.destroy'"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="80%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>
        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import selectTypeExpense from "@/components/filters/inventory/select-type-expense.vue";
import SelectCurrency from "@/components/filters/inventory/select-currency";
import SelectAccount from "@/components/filters/inventory/select-account";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "RegionController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    SelectAccount,
    selectTypeExpense
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      list: "paymentsExpense/list",
      columns: "paymentsExpense/columns",
      pagination: "paymentsExpense/pagination",
      statues: "paymentsExpense/statues",
      filter: "paymentsExpense/filter",
      sort: "paymentsExpense/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "paymentsExpense/index",
      setPagination: "paymentsExpense/setPagination",
      updateSort: "paymentsExpense/updateSort",
      updateFilter: "paymentsExpense/updateFilter",
      updateColumn: "paymentsExpense/updateColumn",
      updatePagination: "paymentsExpense/updatePagination",
      show: "paymentsExpense/show",
      empty: "paymentsExpense/empty",
      delete: "paymentsExpense/destroy",
      refreshData: "paymentsExpense/refreshData"
    })
  }
};
</script>
